export default {
    menu: {
        index: '首页',
        privacyPolicy: '隐私政策',
        registerPolicy: '用户注册政策',
        supports: '技术支持',
        status: {
            success: '操作成功',
            fail: '操作失败'
        }
    },
    info: {
        name: '二手交易APP',
        desc1: '',
        desc2: '',
        download: '免费下载',
        version: '版本',
        slogan: {
            t1: '',
            t2: '',
            t3: '',
        }
    },
    status: {
        success: '操作成功',
        fail: '操作失败'
    }
}
