import Router from 'vue-router'
import Vue from 'vue'

Vue.use(Router)

export const constantRoutes = [
    {
        path: '',
        component: () => import('@/views/index/index'),
        meta: {title: 'menu.index'}
    },
    {
        path: '/privacyPolicy',
        component: () => import('@/views/privacyPolicy/index'),
        meta: {title: 'menu.privacyPolicy'}
    },
    {
        path: '/registerPolicy',
        component: () => import('@/views/registerPolicy/index'),
        meta: {title: 'menu.registerPolicy'}
    },
    {
        path: '/result/success',
        component: () => import('@/views/status/success'),
        meta: {title: 'menu.status.success'}
    },
    {
        path: '/result/fail',
        component: () => import('@/views/status/fail'),
        meta: {title: 'menu.status.fail'}
    },
    {
        path: '/supports',
        component: () => import('@/views/supports/index'),
        meta: {title: 'menu.supports'}
    },
    {
        path: '*',
        redirect: '/'
    },
    {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect')
    },
]

const router = new Router({
    mode: 'history', // 去掉url中的#
    scrollBehavior: () => ({y: 0}),
    routes: constantRoutes
})
router.beforeEach((to, from, next) => {
    let t = to.query.t;
    if(t) {
        localStorage.setItem('tenantId', t)
    }
    next()
})
export default router;
