export default {
  menu: {
    index: 'Home',
    privacyPolicy: 'Privacy Policy',
    registerPolicy: 'User Register Policy',
    supports: 'Supports',
    status: {
      success: 'Success',
      fail: 'Fail'
    }
  },
  info: {
    name: '二手交易APP',
    desc1: '',
    desc2: '',
    download: 'Free Download',
    version: 'Version',
    slogan: {
      t1: '',
      t2: '',
      t3: '',
    }
  },
  status: {
    success: 'Success',
    fail: 'Fail'
  }
}
