<template>
  <el-row :gutter="20" class="navbar">
    <el-col :span="mobile? 12: 18" class="left">
      <el-menu v-if="!mobile" :default-active="activeIndex"
               router
               active-text-color="#409eff"
               mode="horizontal">
        <el-menu-item index="/">{{ $t('menu.index') }}</el-menu-item>
        <el-menu-item index="/supports">{{ $t('menu.supports') }}</el-menu-item>
        <el-menu-item index="/privacyPolicy">{{ $t('menu.privacyPolicy') }}</el-menu-item>
        <el-menu-item index="/registerPolicy">{{ $t('menu.registerPolicy') }}</el-menu-item>
      </el-menu>
      <el-dropdown v-else trigger="click" @command="handleCommand">
        <el-button type="primary" class="el-dropdown-link">
          <i class="el-icon-menu"></i>
        </el-button>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="/">{{ $t('menu.index') }}</el-dropdown-item>
          <el-dropdown-item divided command="/supports">{{ $t('menu.supports') }}</el-dropdown-item>
          <el-dropdown-item divided command="/privacyPolicy">{{ $t('menu.privacyPolicy') }}</el-dropdown-item>
          <el-dropdown-item divided command="/registerPolicy">{{ $t('menu.registerPolicy') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
    <el-col :span="mobile? 12: 6" class="right">
      <el-select v-model="lang" @change="handleLangChange">
        <el-option label="中文" value="zh_CN"></el-option>
        <el-option label="English" value="en_US"></el-option>
      </el-select>
    </el-col>
  </el-row>
</template>

<script>
import {setLang} from "@/i18n";

export default {
  name: "Navbar",
  data() {
    return {
      lang: localStorage.getItem('lang'),
    }
  },
  computed: {
    activeIndex() {
      return this.$route.path;
    },
    mobile() {
      return this.$store.getters.mobile
    }
  },
  methods: {
    handleLangChange(lang) {
      setLang(lang)
      // 重新加载当前页面
      // this.$router.replace({
      //   path: '/redirect' + this.$route.path,
      // })
      location.reload()
    },
    handleCommand(common) {
      if (this.$route.path !== common) {
        this.$router.push(common)
      }
    },
    t() {
      return localStorage.getItem('tenantId')
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  position: sticky;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  margin: 0 !important;
  height: 60px;
  top: 0;
  z-index: 1;
  background-color: #fff;

  .right {
    text-align: right;
    line-height: 60px;
    padding-right: 30px !important;
  }

  .left {
    text-align: left;
    line-height: 60px;
  }
}
</style>
